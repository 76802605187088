import {
  Modal,
  Paper,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Switch,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import StepperTextSelect from "./StepperTextSelect";
import * as Icon from "react-feather";
import IconButton from "../common/IconButton";
import { useEffect, useState } from "react";
import { convertToSeconds } from "../../generalFunctions/convertToSeconds";
import { convertToDate } from "../../generalFunctions/convertToDate";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSolve,
  setDocument,
} from "../../store/timeDataSlice/actionCreators";
import { setTimeResponse } from "../../store/timeDataSlice";
import { setMessageAndOpen } from "../../store/alertDataSlice";
import { markSlowAndFastTimes } from "../../generalFunctions/markSlowAndFastTimes";
import { secondsToMinutes } from "../../generalFunctions/secondsToMinutes";

const styles = {
  container: {
    height: "max-content",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "550px",
  },
  backButton: {
    position: "absolute",
    right: "0%",
    top: "0%",
    padding: "5px 5px 1px 5px",
    marginRight: "5px",
    marginTop: "5px",
    borderRadius: "30px",
    marginLeft: "auto",
    height: "max-content",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "pointer",
    },
  },
};

const EditTimesModal = ({ handleClose, isOpen, timeData }) => {
  const [time, setTime] = useState("");
  const [date, setDate] = useState(null);
  const [checked, setChecked] = useState(false);
  const [markOffical, setMarkOffical] = useState(false);
  const [error, setError] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [ao5Solve, setAo5solve] = useState([]);
  const [toggleResetValue, setToggleResetValue] = useState(true);
  const [ao5SolveComplete, setAo5solveComplete] = useState(false);
  const [valuesEqual, setValuesEqual] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);

  const largerThanMD = useMediaQuery("(min-width:900px)");
  const largerThanSM = useMediaQuery("(min-width:600px)");
  const largerThanXS = useMediaQuery("(min-width:350px)");

  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state) => state.user);
  const timeResponse = useSelector((state) => state.timeData.response);

  const handleModalClose = () => {
    handleClose();
    setToggleResetValue((prev) => !prev);
    setError("");
    setChecked(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedTime = convertToSeconds(time);
    const formattedDate = convertToDate(date);

    let tempError = "";
    if (formattedTime === 0) {
      tempError = "*Invalid time format";
      setTime(timeData.time);
    } else if (formattedDate === "") {
      tempError = "*Invalid date format, year can be (2020 - Current)";
      setDate(timeData.date);
    }

    let newAo5Solve = [];
    if (timeData.type.includes("ao5")) {
      for (let i = 0; i < ao5Solve.length; i++) {
        newAo5Solve[i] = convertToSeconds(ao5Solve[i]);
        if (newAo5Solve[i] === 0) {
          tempError = "*One or more solve times is invalid";
          setToggleResetValue((prev) => !prev);
          break;
        }
      }
    }

    if (tempError.length === 0) {
      let bodyObj = timeData.type.includes("ao5")
        ? {
            time: formattedTime,
            date: formattedDate,
            type: timeData.type,
            comp: markOffical,
            indivSolves: markSlowAndFastTimes(newAo5Solve),
          }
        : {
            time: formattedTime,
            date: formattedDate,
            type: timeData.type,
            comp: markOffical,
          };

      setDisableButton(true);
      dispatch(
        setDocument(
          `${process.env.REACT_APP_DEFAULT_COLLECTION}/${timeData.userUID}/solves/${timeData.id}`,
          bodyObj
        )
      );
    }
    setError(tempError);
  };

  useEffect(() => {
    setMarkOffical(timeData.comp);
  }, [timeData.comp, checked, isOpen]);

  useEffect(() => {
    setTime(timeData.time);
  }, [timeData.time, checked, isOpen]);

  useEffect(() => {
    setDate(timeData.date);
  }, [timeData.date, checked, isOpen]);

  useEffect(() => {
    setError("");
  }, [checked]);

  useEffect(() => {
    const initialValueArray = timeData.indivSolves
      .replace(/[()]/g, "")
      .split(" ");

    if (timeData.indivSolves !== "Not Recorded") {
      if (
        JSON.stringify(initialValueArray) === JSON.stringify(ao5Solve) &&
        timeData.time === secondsToMinutes(convertToSeconds(time)) &&
        timeData.date === date &&
        timeData.comp === markOffical
      )
        setValuesEqual(true);
      else setValuesEqual(false);
    } else {
      if (
        timeData.time === secondsToMinutes(convertToSeconds(time)) &&
        timeData.date === date &&
        timeData.comp === markOffical
      )
        setValuesEqual(true);
      else setValuesEqual(false);
    }
  }, [
    timeData.time,
    timeData.date,
    timeData.comp,
    timeData.indivSolves,
    time,
    date,
    markOffical,
    ao5Solve,
  ]);

  useEffect(() => {
    if (ao5Solve.length === 0) return;

    ao5Solve.forEach((el) => {
      if (el.length === 0) {
        setAo5solveComplete(false);
        return;
      }
      setAo5solveComplete(true);
    });
  }, [ao5Solve]);

  useEffect(() => {
    const handleWriteRequestErrors = () => {
      if (timeResponse.length > 0) {
        if (timeResponse === "successUpdated") {
          dispatch(setMessageAndOpen("Time Successfully Updated!"));
          handleModalClose();
        } else if (timeResponse === "successDeleted") {
          dispatch(setMessageAndOpen("Time Successfully Deleted!"));
          handleModalClose();
        } else if (timeResponse !== "success") {
          setError(timeResponse);
          setToggleResetValue((prev) => !prev);
          setTime(timeData.time);
          setDate(timeData.date);
          setMarkOffical(timeData.comp);
        }
        dispatch(setTimeResponse(""));
        setDisableButton(false);
      }
    };

    handleWriteRequestErrors();
    // eslint-disable-next-line
  }, [dispatch, timeResponse]);

  return (
    <>
      <Modal
        onClose={handleModalClose}
        open={isOpen}
        disableAutoFocus
        closeAfterTransition
      >
        <Grid
          component={Paper}
          container
          sx={{
            ...styles.container,
            width: largerThanMD
              ? "60%"
              : largerThanSM
              ? "75%"
              : largerThanXS
              ? "90%"
              : "100%",
          }}
          direction="column"
          wrap="nowrap"
        >
          <Grid sx={styles.backButton} onClick={handleModalClose}>
            <Icon.X size={30} />
          </Grid>
          <Grid item container justifyContent="center">
            <Typography sx={{ margin: "20px 10px 0px 10px" }} variant="h5">
              User: {timeData.name}{" "}
              {timeData.userUID === user.uid ? "(You)" : ""}
            </Typography>
          </Grid>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              mt: 3,
              width: "80%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <TextField
                  variant="standard"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  fullWidth
                  id="time"
                  label={
                    timeData.type.includes("solo")
                      ? `Solve Time (${timeData.type.replace("solo", "")})`
                      : `Average Time (${timeData.type.replace("ao5", "")})`
                  }
                  name="time"
                  autoComplete="time"
                  disabled={!checked}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date Of Solve"
                      value={date ? dayjs(date) : null}
                      onChange={(e) => {
                        // eslint-disable-next-line
                        if (e.$M !== NaN && e.$D !== NaN && e.$y !== NaN)
                          setDate(`${e.$M + 1}/${e.$D}/${e.$y}`);
                      }}
                      disabled={!checked}
                      disableFuture
                      slotProps={{
                        textField: {
                          variant: "standard",
                          fullWidth: true,
                          id: "date",
                          InputLabelProps: { shrink: true },
                        },
                      }}
                      sx={{
                        "& .MuiButtonBase-root": {
                          marginRight: 0,
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              {timeData.type.includes("ao5") && (
                <StepperTextSelect
                  onValuesChange={(values) => {
                    setAo5solve(values);
                  }}
                  toggleResetValue={toggleResetValue}
                  disabled={!checked}
                  initialValue={
                    timeData.indivSolves === "Not Recorded"
                      ? null
                      : timeData.indivSolves
                  }
                />
              )}
              <Grid style={{ padding: "10px 0px 10px 20px" }} item container>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={
                        !(
                          isAdmin ||
                          (timeData.userUID === user.uid && !timeData.comp)
                        )
                      }
                      checked={checked}
                      onChange={() => {
                        setChecked((state) => !state);
                      }}
                    />
                  }
                  label={
                    (timeData.userUID === user.uid && !timeData.comp) || isAdmin
                      ? checked
                        ? "Disable Edit Mode"
                        : "Enable Edit Mode"
                      : "Cannot Edit Time"
                  }
                />
                {isAdmin && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={!checked}
                          checked={markOffical}
                          onChange={() => {
                            setMarkOffical((prev) => !prev);
                          }}
                        />
                      }
                      label="Mark Official"
                    />
                    <Tooltip
                      title={checked ? "Delete Solve" : ""}
                      arrow
                      placement="top"
                    >
                      <Grid sx={{ marginLeft: "auto", paddingTop: "7px" }}>
                        <IconButton
                          disabled={!checked}
                          iconName="Trash2"
                          color="red"
                          size={30}
                          onButtonClick={() => {
                            setTimeout(() => {
                              setDialogOpen(true);
                            }, 300);
                            handleModalClose();
                          }}
                        />
                      </Grid>
                    </Tooltip>
                  </>
                )}
                {error.length > 0 && (
                  <Typography color="#ff5555" variant="subtitle2">
                    {error}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {checked && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 2 }}
                disabled={
                  time.length === 0 ||
                  !date ||
                  !checked ||
                  disableButton ||
                  (timeData.type.includes("ao5") && !ao5SolveComplete) ||
                  valuesEqual
                }
              >
                Update Time
              </Button>
            )}
          </Box>
        </Grid>
      </Modal>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>Delete Solve</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Delete Solve id: ${timeData.id}?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              dispatch(deleteSolve(timeData.userUID, timeData.id));
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditTimesModal;
