import { Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";

const styles = {
  container: {
    margin: "10px",
    marginBottom: "2px",
    width: "auto",
  },
  item: {
    border: "1px solid black",
    padding: "3px",
    marginRight: "5px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "pointer",
    },
  },
};

const TableSelector = ({
  array = [],
  onSelectionChange = () => {},
  passThroughValue,
}) => {
  const [value, setValue] = useState(0);

  const handleClick = (index) => {
    setValue(index);
    onSelectionChange(index);
  };

  useEffect(() => {
    setValue(passThroughValue);
  }, [passThroughValue]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={styles.container}
      wrap="nowrap"
    >
      {array.map((ele, index) => (
        <Grid
          item
          sx={{
            ...styles.item,
            backgroundColor: value === index ? "#e7e7e7" : null,
          }}
          key={ele}
          onClick={() => {
            handleClick(index);
          }}
        >
          <Typography variant="body2">{ele}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default TableSelector;
