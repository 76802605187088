import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  severity: "success",
  message: "",
  open: false,
};

const alertDataSlice = createSlice({
  name: "alertData",
  initialState,
  reducers: {
    setMessageAndOpen: (state, action) => {
      state.message = action.payload;
      state.open = true;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setSeverity: (state, action) => {
      state.severity = action.payload;
    },
  },
});

export const { setMessageAndOpen, setOpen, setSeverity } =
  alertDataSlice.actions;

export default alertDataSlice.reducer;
