import { db } from "../../configurations/firebase-config";
import { collection, getDocs, doc, setDoc, deleteDoc } from "firebase/firestore";
import { setUsers, setUsersLoading, setUsersReadResponse, setUsersResponse } from ".";
import { getTimeData } from "../timeDataSlice/actionCreators";

export const getAllUsers = () => {
  return async (dispatch) => {
    let runningTasks = [
      getDocs(collection(db, process.env.REACT_APP_DEFAULT_COLLECTION)),
      getDocs(collection(db, process.env.REACT_APP_DEFAULT_ROLE_COLLECTION)),
    ];

    setUsersLoading(true);
    try {
      let promiseResponse = await Promise.all(runningTasks);

      let userData = promiseResponse[0].docs.map((user) => ({
        ...user.data(),
        id: user.id,
      }));

      let roleData = promiseResponse[1].docs.map((userRole) => ({
        ...userRole.data(),
        id: userRole.id,
      }));

      userData.forEach((user) => {
        let roleOfUser = roleData.find((userRole) => userRole.id === user.id);
        user.role = roleOfUser ? roleOfUser.role : "Member";
      });

      dispatch(setUsers(userData));
      dispatch(setUsersReadResponse(""));
    } catch (error) {
      dispatch(setUsersReadResponse(error.code));
    }
    setUsersLoading(false);
  };
};

export const changeUserRole = (userID, newRole) => {
  return async (dispatch) => {
    setUsersLoading(true);
    try {
      await setDoc(
        doc(db, `${process.env.REACT_APP_DEFAULT_ROLE_COLLECTION}/${userID}`),
        { role: newRole }
      );
      dispatch(setUsersResponse("success"));
    } catch (error) {
      dispatch(setUsersResponse(error.code));
    }
    dispatch(getAllUsers());
  };
};

export const deleteUserInfo = (userID) => {
  return async (dispatch) => {
    setUsersLoading(true);
    try {
      await deleteDoc(doc(db, `${process.env.REACT_APP_DEFAULT_COLLECTION}/${userID}`));
      await deleteDoc(doc(db, `${process.env.REACT_APP_DEFAULT_ROLE_COLLECTION}/${userID}`));

      dispatch(getTimeData());
      dispatch(setUsersResponse("successDeleted"));
    } catch (error) {
      dispatch(setUsersResponse(error.code));
    }
    dispatch(getAllUsers());
  };
};
