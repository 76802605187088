import { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import data from "./roles.json";
import { useDispatch, useSelector } from "react-redux";
import { setMessageAndOpen, setSeverity } from "../../store/alertDataSlice";
import {
  changeUserRole,
  deleteUserInfo,
} from "../../store/allUsersSlice/actionCreators";
import { setUsersResponse } from "../../store/allUsersSlice";
import IconButton from "../common/IconButton";

const Users = () => {
  const userData = useSelector((state) => state.user.user);
  const { users, response, readResponse } = useSelector(
    (state) => state.allUsers
  );
  const [roleValues, setRoleValues] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [userToDelete, setUserToDelete] = useState("");
  const dispatch = useDispatch();

  useDocumentTitle("Zips Cubing - Users");

  useEffect(() => {
    const handleUserInfoChangeResponse = () => {
      if (response.length > 0) {
        if (response === "success") {
          dispatch(setMessageAndOpen("Successfully Updated User Role!"));
        } else if (response === "successDeleted") {
          dispatch(setMessageAndOpen("Successfully Deleted User"));
        } else {
          dispatch(setSeverity("error"));
          dispatch(setMessageAndOpen(`Error: ${response}`));
        }
        dispatch(setUsersResponse(""));
      }
    };

    handleUserInfoChangeResponse();
  }, [dispatch, response]);

  useEffect(() => {
    setRoleValues(users.map((el) => el.role));
  }, [users]);

  const handleChangeUserRole = (index, newRole) => {
    setRoleValues((prev) => {
      let newArray = [...prev];
      newArray[index] = newRole;
      return newArray;
    });

    dispatch(changeUserRole(users[index].id, newRole));
  };

  const handleDeleteUser = () => {
    if (userToDelete !== "") {
      dispatch(deleteUserInfo(userToDelete));
      setUserToDelete("");
    }
  };

  return (
    <>
      {readResponse.length === 0 ? (
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  UAID
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  Student ID
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 600 }}>
                  Role
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (
                <TableRow key={user.id} sx={{ width: "50px" }}>
                  <TableCell style={{ width: 30 }}>
                    <Tooltip
                      title={
                        roleValues[index] === "Admin"
                          ? "Cannot Delete Admin"
                          : "Delete User"
                      }
                      arrow
                    >
                      <Grid>
                        <IconButton
                          iconName="Trash2"
                          color="red"
                          disabled={roleValues[index] === "Admin"}
                          onButtonClick={() => {
                            setDialogOpen(true);
                            setDialogMessage(
                              `Delete ${user.name}'s (${user.uaid}) Information?`
                            );
                            setUserToDelete(user.id);
                          }}
                        />
                      </Grid>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell align="center">{user.uaid}</TableCell>
                  <TableCell align="center">{user.studentID}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={
                        user.id === userData.uid ? "Cannot Change Own Role" : ""
                      }
                      arrow
                    >
                      <FormControl variant="standard">
                        <Select
                          disabled={user.id === userData.uid}
                          value={roleValues.length > 0 ? roleValues[index] : ""}
                          sx={{ fontWeight: 400, fontSize: 14 }}
                          onChange={(event) => {
                            handleChangeUserRole(index, event.target.value);
                          }}
                        >
                          {data.map((role) => (
                            <MenuItem key={role} value={role}>
                              {role}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography marginTop="30px" align="center" variant="h3">
          OOPS... Something went wrong
        </Typography>
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
              setUserToDelete("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDialogOpen(false);
              handleDeleteUser();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Users;
