const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const convertDateToNumbers = (date) => {
  let month, day, year = "";
  MONTHS.forEach((el, index) => {
    if (date.includes(el)) month = index + 1;
  });

  let dateArray = date.replace(MONTHS[month - 1], "").split(", ");
  day = Number(dateArray[0]);
  year = Number(dateArray[1]);

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;

  return `${month}/${day}/${year}`;
};
