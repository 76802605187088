import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser } from "../store/userSlice/actionCreators";
import { setResponse } from "../store/userSlice";
import { setMessageAndOpen } from "../store/alertDataSlice";
import { useEffect, useState } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";

export default function SignUp() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userResponse = useSelector((state) => state.user.response);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [uaid, setUaid] = useState("");
  const [studentID, setStudentID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [reinstateUser, setReinstateUser] = useState(false);

  useDocumentTitle("Zips Cubing - Sign Up");

  const handleSubmit = (event) => {
    event.preventDefault();
    let tempUAID = uaid.replace("@uakron.edu", "").trim();

    if (!isNaN(studentID) && studentID.length === 7) {
      setError("");
      setDisableButton(true);
      dispatch(
        createUser(
          `${
            firstName[0].toUpperCase() + firstName.substring(1).toLowerCase()
          } ${
            lastName[0].toUpperCase() + lastName.substring(1).toLowerCase()
          }`.trim(),
          tempUAID,
          `${tempUAID}@uakron.edu`,
          studentID.trim(),
          password.trim(),
          reinstateUser
        )
      );
    } else {
      setStudentID("");
      setError("*Student ID should be 7 numbers");
    }
  };

  useEffect(() => {
    const handleUserCreationErrors = () => {
      if (userResponse.length > 0) {
        if (userResponse === "auth/weak-password") {
          setError("*Password should be at least 6 characters");
          setPassword("");
        } else if (userResponse === "auth/email-already-in-use") {
          setError(`*The user ${uaid} already exists`);
          setUaid("");
          setPassword("");
        } else if (userResponse === "auth/internal-error") {
          setError("*Internal server error, try again later");
          setFirstName("");
          setLastName("");
          setUaid("");
          setStudentID("");
          setPassword("");
        } else if (userResponse === "success") {
          navigate("/results");
          setReinstateUser(false);
          dispatch(
            setMessageAndOpen("User Successfully Created And Logged In!")
          );
        } else if (userResponse === "ReinstateUser2") {
          setReinstateUser(true);
        } else {
          setError(userResponse);
        }
        dispatch(setResponse(""));
        setDisableButton(false);
      }
    };

    handleUserCreationErrors();
    // eslint-disable-next-line
  }, [dispatch, navigate, userResponse]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {reinstateUser ? "Reinstate User" : "Sign up"}
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
              />
              <Grid item xs={12}></Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={studentID}
                onChange={(e) => setStudentID(e.target.value)}
                required
                fullWidth
                id="studentID"
                label="Student ID... ex: 1234567"
                name="stuId"
              />
            </Grid>
            {!reinstateUser && (
              <>
                <Grid item xs={12}>
                  <TextField
                    value={uaid}
                    onChange={(e) => setUaid(e.target.value)}
                    required
                    fullWidth
                    id="email"
                    label="UAID... ex: cpm53"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              {error.length > 0 && (
                <Typography
                  style={{ marginTop: "4px" }}
                  color="#ff5555"
                  variant="subtitle2"
                >
                  {error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              reinstateUser
                ? firstName === "" ||
                  lastName === "" ||
                  studentID === "" ||
                  disableButton
                : firstName === "" ||
                  lastName === "" ||
                  uaid === "" ||
                  studentID === "" ||
                  password === "" ||
                  disableButton
            }
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
