import { db } from "../../configurations/firebase-config";
import { collection, getDocs, doc, setDoc, addDoc, deleteDoc } from "firebase/firestore";
import { setTimeResponse, setTimeReadResponse, setData, setIsLoading } from ".";
import { secondsToMinutes } from "../../generalFunctions/secondsToMinutes";

export const getTimeData = () => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));

    let allSolves = [];
    const data = await getCollectionWithId(
      process.env.REACT_APP_DEFAULT_COLLECTION,
      dispatch
    );
    const userCalls = [];
    data.forEach((user) => {
      userCalls.push(
        getCollectionWithId(
          `${process.env.REACT_APP_DEFAULT_COLLECTION}/${user.id}/solves`,
          dispatch
        )
      );
    });

    try {
      const userCallResults = await Promise.all(userCalls);
      data.forEach((user, index) => {
        userCallResults[index].forEach((solve) => {
          allSolves.push({
            ...solve,
            indivSolves: solve.indivSolves ? solve.indivSolves : "Not Recorded",
            time: secondsToMinutes(solve.time),
            name: user.name,
            uaid: user.uaid,
            userUID: user.id,
          });
        });
      });
      dispatch(setData(allSolves));
      dispatch(setTimeReadResponse(""));
    } catch (error) {
      dispatch(setTimeReadResponse(error.code));
    }

    dispatch(setIsLoading(false));
  };
};

const getCollectionWithId = async (path, dispatch) => {
  try {
    const response = await getDocs(collection(db, path));
    return response.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  } catch (error) {
    dispatch(setTimeReadResponse(error.code));
  }
};

export const setDocument = (path, fields, randomID = false) => {
  return async (dispatch) => {
    try {
      let successMessage = "";
      if (randomID) {
        await addDoc(collection(db, path), fields);
        successMessage = "success";
      } else {
        await setDoc(doc(db, path), fields);
        successMessage = "successUpdated";
      }
      if (path.includes("solves")) {
        dispatch(setTimeResponse(successMessage));
        dispatch(getTimeData());
      }
    } catch (error) {
      dispatch(setTimeResponse(error.code));
    }
  };
};

export const deleteSolve = (userUID, solveID) => {
  return async (dispatch) => {
    try {
      await deleteDoc(doc(db, `${process.env.REACT_APP_DEFAULT_COLLECTION}/${userUID}/solves/${solveID}`));
      dispatch(getTimeData());
      dispatch(setTimeResponse("successDeleted"));
    } catch (error) {
      dispatch(setTimeResponse(error.code));
    }
  };
};
