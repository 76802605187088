import { Grid, Typography } from "@mui/material";
import InfoPageWrapper from "../common/InfoPageWrapper";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ListCard from "./ListCard";
import rulesData from "./rulesData.json";

const Rules = () => {
  useDocumentTitle("Zips Cubing - Rules");

  return (
    <InfoPageWrapper>
      <Grid container item direction="column">
        <Typography marginLeft="10px" variant="h4">Membership Rules</Typography>
        {rulesData.map((item, index) => (
          <ListCard
            information={item.information}
            list={item.list}
            key={index}
          />
        ))}
      </Grid>
    </InfoPageWrapper>
  );
};

export default Rules;
