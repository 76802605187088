import { convertToSeconds } from "../../generalFunctions/convertToSeconds";

const createAndSortTimesByKey = (objArr, key) => {
  return [...objArr]
    .filter((obj) => obj.type === key)
    .sort((a, b) => {
      let keyA = convertToSeconds(a.time);
      let keyB = convertToSeconds(b.time);

      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
};

export default createAndSortTimesByKey;
