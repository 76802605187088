import { Grid, Typography } from "@mui/material";
import * as Icon from "react-feather";

const styles = {
  container: {
    backgroundColor: "#f8f8f8",
    padding: "10px 10px",
    marginTop: "20px",
    borderRadius: "10px",
  },
};

const QuestionCard = ({ question, answer, iconName }) => {
  let IconTag;
  if (iconName) IconTag = Icon[iconName];

  return (
    <Grid container item sx={styles.container} direction="column">
      <Grid container item>
        {iconName && (
          <Grid item sx={{marginRight: "5px"}}>
            <IconTag />
          </Grid>
        )}
        <Grid item>
          <Typography fontSize="20px" variant="body2">
            {question}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{answer}</Typography>
      </Grid>
    </Grid>
  );
};

export default QuestionCard;
