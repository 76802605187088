import {
  Button,
  TextField,
  Grid,
  Box,
  Typography,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  resetUserPassword,
} from "../store/userSlice/actionCreators";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { setPasswordResetResponse, setResponse } from "../store/userSlice";
import { setMessageAndOpen } from "../store/alertDataSlice";

const styles = {
  linkText: {
    fontSize: "15px",
    color: "#551a8b",
    textDecorationLine: "underline",
    marginTop: "-2px",
    fontWeight: "450",
    "&:hover": {
      cursor: "pointer",
    },
  },
};

const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userResponse = useSelector((state) => state.user.response);
  const userPasswordResetResponse = useSelector(
    (state) => state.user.passwordResetResponse
  );

  const [uaid, setUaid] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  useDocumentTitle("Zips Cubing - Login");

  const handleSubmit = (event) => {
    event.preventDefault();
    let email = "";

    if (uaid.includes("@uakron.edu")) email = uaid.trim();
    else email = `${uaid.trim()}@uakron.edu`;

    if (resetPassword) {
      dispatch(resetUserPassword(email));
    } else {
      dispatch(loginUser(email, password.trim()));
    }
    setError("");
    setDisableButton(true);
  };

  useEffect(() => {
    const handleUserPasswordResetResponse = () => {
      if (userPasswordResetResponse.length > 0) {
        if (userPasswordResetResponse === "success") {
          setResetPassword(false);
          dispatch(
            setMessageAndOpen(
              "Password Recovery Email Sent (It may take a minute)"
            )
          );
        } else if (
          userPasswordResetResponse === "auth/invalid-email" ||
          userPasswordResetResponse === "auth/user-not-found"
        ) {
          setError("*No existing user found");
          setUaid("");
        } else if (userPasswordResetResponse === "auth/internal-error") {
          setError("*Internal server error, try again later");
          setUaid("");
        } else {
          setError(userPasswordResetResponse);
        }
        dispatch(setPasswordResetResponse(""));
        setDisableButton(false);
      }
    };
    handleUserPasswordResetResponse();
  }, [dispatch, userPasswordResetResponse]);

  useEffect(() => {
    const handleUserLoginErrors = () => {
      if (userResponse.length > 0) {
        if (
          userResponse === "auth/invalid-email" ||
          userResponse === "auth/user-not-found"
        ) {
          setError("*No existing user found");
          setUaid("");
          setPassword("");
        } else if (userResponse === "auth/wrong-password") {
          setError("*Invalid password");
          setPassword("");
        } else if (userResponse === "auth/internal-error") {
          setError("*Internal server error, try again later");
          setUaid("");
          setPassword("");
        } else if (userResponse === "success") {
          navigate("/results");
          dispatch(setMessageAndOpen("Login Successful!"));
        } else {
          setError(userResponse);
        }
        dispatch(setResponse(""));
        setDisableButton(false);
      }
    };

    handleUserLoginErrors();
  }, [dispatch, navigate, userResponse]);

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {resetPassword ? "Reset Password" : "Login"}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <Grid container spacing={2} direction="column">
            <Grid item>
              <TextField
                value={uaid}
                onChange={(e) => setUaid(e.target.value)}
                required
                fullWidth
                id="email"
                label="UAID... ex: cpm53"
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item>
              {!resetPassword && (
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              )}
              {error.length > 0 && (
                <Typography color="#ff5555" variant="subtitle2">
                  {error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              uaid === "" ||
              (password === "" && !resetPassword) ||
              disableButton
            }
          >
            {resetPassword ? "Send Recovery Email" : "Login"}
          </Button>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                onClick={() => {
                  setResetPassword((state) => !state);
                  setPassword("");
                  setError("");
                }}
                sx={styles.linkText}
              >
                {resetPassword ? "Back to Login" : "Forgot password?"}
              </Typography>
            </Grid>
            {!resetPassword && (
              <Grid item>
                <Link to="/signup" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LogIn;
