import { Grid, Typography, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { signOutUser } from "../../store/userSlice/actionCreators";
import { useLocation } from "react-router-dom";
import MenuPopup from "./MenuPopup";
import NavBarItem from "./NavBarItem";
import logo from "../../images/zip-cubing-logo.png";
import data from "./navbarData.json";
import { useEffect, useState } from "react";

const styles = {
  container: {
    backgroundColor: "#f8f8f8",
    height: "60px",
    borderBottom: "1px solid #e7e7e7",
    position: "fixed",
    top: 0,
    left: 0,
  },
  logo: {
    width: "45px",
    height: "45px",
    borderRadius: "5px",
    marginLeft: "5px",
  },
};

const NavBar = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const largerThanSM = useMediaQuery("(min-width: 700px)");
  const { pathname } = useLocation();

  const [currentPageName, setCurrentPageName] = useState("");

  useEffect(() => {
    let pageName = data.find((route) => pathname === route.href)?.name;

    if (pathname === "/login") pageName = "Login";
    else if (pathname === "/signup") pageName = "Sign Up";
    else if (!pageName) pageName = "";

    setCurrentPageName(pageName);
  }, [pathname]);

  const handleClick = () => {
    dispatch(signOutUser());
  };

  const loggedIn = user.user.hasOwnProperty("uid") && !user.loading;

  let navBarArray = [...data];
  if (!user.isAdmin) navBarArray.pop();

  return (
    <Grid
      container
      item
      sx={styles.container}
      alignItems="center"
      wrap="nowrap"
    >
      <img src={logo} style={styles.logo} alt="" />
      {largerThanSM &&
        navBarArray.map((item) => (
          <NavBarItem
            key={item.name}
            name={item.name}
            href={item.href}
            iconName={item.iconName}
            additionalStyles={{
              marginLeft: "5px",
              backgroundColor: pathname === item.href ? "#e7e7e7" : "inherit",
            }}
          />
        ))}
      {!largerThanSM && (
        <Typography marginLeft="10px" fontWeight="500px" variant="h6">
          {currentPageName}
        </Typography>
      )}
      <Typography
        variant="subtitle2"
        sx={{ marginLeft: "auto", marginRight: "10px" }}
      >
        {loggedIn
          ? `Welcome, ${user.displayName}`
          : pathname === "/signup"
          ? ""
          : "New to Zips Cubing?"}
      </Typography>
      {!largerThanSM && <MenuPopup onSignOut={handleClick} />}
      {largerThanSM && !loggedIn && pathname !== "/signup" && (
        <NavBarItem
          name="Sign Up"
          href="/signup"
          iconName="Edit"
          additionalStyles={{
            marginRight: "5px",
          }}
        />
      )}
      {largerThanSM && pathname !== "/login" && (
        <NavBarItem
          name={loggedIn ? "Sign Out" : "Login"}
          href="/login"
          iconName={loggedIn ? "LogOut" : "LogIn"}
          additionalStyles={{
            marginRight: "5px",
          }}
          onClick={loggedIn ? handleClick : undefined}
          iconStyles={{ marginTop: "2px" }}
        />
      )}
    </Grid>
  );
};

export default NavBar;
