import { Grid, Typography } from "@mui/material";
import * as Icon from "react-feather";

const styles = {
  container: {
    width: "max-content",
    borderRadius: "5px",
    padding: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  text: {
    marginLeft: "3px",
  },
};

const Button = ({
  iconName,
  children,
  onClick = () => {},
  color,
  additonalStyles,
}) => {
  const IconTag = Icon[iconName];

  return (
    <Grid
      sx={{ ...styles.container, ...additonalStyles }}
      container
      alignItems="center"
      onClick={onClick}
    >
      <Grid item>
        <IconTag color={color} size={20} />
      </Grid>
      <Grid item>
        <Typography sx={styles.text} color={color} variant="subtitle2">
          {children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Button;
