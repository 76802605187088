import { Grid, Modal, Slide } from "@mui/material";
import NavBarItem from "./NavBarItem";
import data from "./navbarData.json";
import { useState } from "react";
import * as Icon from "react-feather";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const styles = {
  modalContainer: {
    backgroundColor: "#ffffff",
    position: "absolute",
    right: 0,
    top: 0,
    height: "100%",
    width: "50%",
    padding: "10px 5px",
    paddingTop: "45px",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  backButton: {
    position: "absolute",
    right: "0%",
    top: "0%",
    padding: "5px 5px 1px 5px",
    marginRight: "5px",
    marginTop: "5px",
    borderRadius: "30px",
    marginLeft: "auto",
    height: "max-content",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "pointer",
    },
  },
  navbarItems: {
    width: "100%",
    borderBottom: "1px solid #e7e7e7",
    marginTop: "5px",
  },
};

const MenuPopup = ({ onSignOut }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const { pathname } = useLocation();

  const loggedIn = user.user.hasOwnProperty("uid");

  let navBarArray = [...data];
  if (!user.isAdmin) navBarArray.pop();

  return (
    <>
      <NavBarItem
        iconName="Menu"
        onClick={() => setMenuOpen(true)}
        additionalStyles={{ marginRight: "5px" }}
        iconStyles={{ marginTop: "4px" }}
      />
      <Modal
        onClose={() => setMenuOpen(false)}
        open={menuOpen}
        disableAutoFocus
        closeAfterTransition
      >
        <Slide in={menuOpen} direction="left">
          <Grid container sx={styles.modalContainer} direction="column">
            <Grid
              sx={styles.backButton}
              onClick={() => {
                setMenuOpen(false);
              }}
            >
              <Icon.X size={30} />
            </Grid>
            {navBarArray.map((obj) => (
              <NavBarItem
                key={obj.name}
                name={obj.name}
                iconName={obj.iconName}
                href={obj.href}
                additionalStyles={{
                  ...styles.navbarItems,
                  backgroundColor:
                    pathname === obj.href ? "#e7e7e7" : "inherit",
                }}
                onClick={() => {
                  setMenuOpen(false);
                }}
              />
            ))}
            <Grid container item sx={{ marginTop: "40px" }}>
              {!loggedIn && pathname !== "/signup" && (
                <NavBarItem
                  name="Sign Up"
                  href="/signup"
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                  iconName="Edit"
                  additionalStyles={styles.navbarItems}
                />
              )}
              {pathname !== "/login" && (
                <NavBarItem
                  name={loggedIn ? "Sign Out" : "Login"}
                  href="/login"
                  onClick={() => {
                    setMenuOpen(false);
                    loggedIn && onSignOut();
                  }}
                  iconName={loggedIn ? "LogOut" : "LogIn"}
                  additionalStyles={styles.navbarItems}
                  iconStyles={{ marginTop: "2px" }}
                />
              )}
            </Grid>
          </Grid>
        </Slide>
      </Modal>
    </>
  );
};

export default MenuPopup;
