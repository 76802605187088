import { secondsToMinutes } from "./secondsToMinutes";

export const markSlowAndFastTimes = (ao5Values) => {
  let newString = "";
  let minIndex = ao5Values.indexOf(Math.min(...ao5Values));
  let maxIndex = ao5Values.indexOf(Math.max(...ao5Values));

  for (let i = 0; i < ao5Values.length; i++) {
    if (i === minIndex || i === maxIndex)
      newString += `(${secondsToMinutes(ao5Values[i])})`;
    else newString += secondsToMinutes(ao5Values[i]);

    if (i < ao5Values.length - 1) newString += " ";
  }

  return newString;
};
