import { configureStore } from "@reduxjs/toolkit";
import timeDataSlice from "./timeDataSlice";
import userSlice from "./userSlice";
import alertDataSlice from "./alertDataSlice";
import allUsersSlice from "./allUsersSlice";

export const store = configureStore({
  reducer: {
    timeData: timeDataSlice,
    user: userSlice,
    alertData: alertDataSlice,
    allUsers: allUsersSlice,
  },
});
