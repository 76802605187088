import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  displayName: "",
  isAdmin: false,
  response: "",
  passwordResetResponse: "",
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    setPasswordResetResponse: (state, action) => {
      state.passwordResetResponse = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setUserLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
  },
});

export const {
  setUser,
  setResponse,
  setPasswordResetResponse,
  setDisplayName,
  setUserLoading,
  setIsAdmin,
} = userSlice.actions;

export default userSlice.reducer;
