import { auth } from "../../configurations/firebase-config";
import { db } from "../../configurations/firebase-config";
import { getDoc, doc } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";
import { getTimeData, setDocument } from "../timeDataSlice/actionCreators";
import { getAllUsers } from "../allUsersSlice/actionCreators";
import {
  setUserLoading,
  setResponse,
  setDisplayName,
  setPasswordResetResponse,
  setUser,
  setIsAdmin,
} from ".";

export const getUserInfo = (user) => {
  return async (dispatch) => {
    dispatch(setUserLoading(true));
    try {
      let userRolePromise = await getDoc(
        doc(db, `${process.env.REACT_APP_DEFAULT_ROLE_COLLECTION}/${user.uid}`)
      );
      let userInfoPromise = await getDoc(
        doc(db, `${process.env.REACT_APP_DEFAULT_COLLECTION}/${user.uid}`)
      );
      let userInfo = userInfoPromise.data();
      let userRole = userRolePromise.data();

      dispatch(
        setUser({
          ...user,
          uaid: userInfo?.uaid,
          studentID: userInfo?.studentID,
          role: userRole ? userRolePromise.role : "Member",
        })
      );
      dispatch(setIsAdmin(Boolean(userRole && userRole.role === "Admin")));
      if (userRole && userRole.role === "Admin") dispatch(getAllUsers());
      
      if (!userInfo) dispatch(setResponse("ReinstateUser"));
      else dispatch(setResponse(""));
    } catch (error) {
      if (error.code) dispatch(setResponse(error.code));
      else dispatch(setResponse("Console Eror"));
    }
    dispatch(setUserLoading(false));
  };
};

export const createUser = (name, uaid, email, studentID, password, reinstateUser = false) => {
  return async (dispatch) => {
    dispatch(setUserLoading(true));
    try {
      let user;
      if (!reinstateUser) {
        const userPromise = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await updateProfile(userPromise.user, { displayName: name });
        user = userPromise.user.toJSON();
      } else {
        await updateProfile(auth.currentUser, { displayName: name });
        user = auth.currentUser;
      }

      dispatch(
        setDocument(`${process.env.REACT_APP_DEFAULT_COLLECTION}/${user.uid}`, {
          name,
          uaid: reinstateUser ? user.email.replace("@uakron.edu", "") : uaid,
          studentID,
        })
      );
      dispatch(setDisplayName(name));
      dispatch(setResponse("success"));
      if (reinstateUser) dispatch(getTimeData());
    } catch (error) {
      dispatch(setResponse(error.code));
    }
    dispatch(setUserLoading(false));
  };
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    dispatch(setUserLoading(true));
    try {
      await signInWithEmailAndPassword(auth, email, password);
      dispatch(setResponse("success"));
    } catch (error) {
      dispatch(setResponse(error.code));
    }
    dispatch(setUserLoading(false));
  };
};

export const resetUserPassword = (email) => {
  return async (dispatch) => {
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch(setPasswordResetResponse("success"));
    } catch (error) {
      dispatch(setPasswordResetResponse(error.code));
    }
  };
};

export const signOutUser = () => {
  return () => {
    signOut(auth);
  };
};
