import { Grid, Typography, useMediaQuery } from "@mui/material";
import InfoPageWrapper from "../common/InfoPageWrapper";
import QuestionCard from "./QuestionCard";
import logo from "../../images/zip-cubing-logo.png";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const styles = {
  image: {
    borderRadius: "10px",
    width: "350px",
    height: "350px",
  },
};

const Home = () => {
  useDocumentTitle("Zips Cubing - Home");
  const largerThanMD = useMediaQuery("(min-width:900px)");

  return (
    <InfoPageWrapper>
      <Grid
        container
        item
        direction="row-reverse"
        justifyContent="space-between"
      >
        {largerThanMD && (
          <Grid
            container
            item
            md={5.9}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <img src={logo} alt="" style={styles.image} />
            </Grid>
          </Grid>
        )}
        <Grid container item xs={12} md={5.9}>
          <Grid item>
            <Typography marginLeft="10px" variant="h4">
              Welcome to Zips Cubing
            </Typography>
          </Grid>
          <Grid item>
            <QuestionCard
              question="Who are we?"
              answer="Zips Cubing aims to provide organization for a community of students interested in solving various twisty-puzzles (i.e. the Rubik's Cube) and provide opportunities for new or experienced speedcubers to learn, practice, share puzzles, and compete with other members."
              iconName="Box"
            />
          </Grid>
          <Grid item>
            <QuestionCard
              question="Interested in joining?"
              answer="You're in luck! Signing up for Zips Cubing is completely free if you are a University of Akron student. Navigate to our sign up page and create an account."
              iconName="Edit"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-between">
        <Grid item xs={12} md={5.9}>
          <QuestionCard
            question="Growing Database"
            answer="We store the solve times for every user in order to compare with others and help cubers grow in skill. Feel free to add your own times after you sign up."
            iconName="Database"
          />
        </Grid>
        <Grid item xs={12} md={5.9}>
          <QuestionCard
            question="Contact Information"
            answer="If you have any questions or concerns, feel free to email the Zips Cubing President, Bruce Newlands, at bn40@uakron.edu."
            iconName="Mail"
          />
        </Grid>
      </Grid>
    </InfoPageWrapper>
  );
};

export default Home;
