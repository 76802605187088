import { Grid } from "@mui/material";
import * as Icon from "react-feather";

const styles = {
  button: {
    "&:hover": {
      cursor: "pointer",
    },
  },
};

const IconButton = ({
  disabled = false,
  iconName,
  color,
  onButtonClick = () => {},
  size = 26,
}) => {
  const DynamicIcon = Icon[iconName];

  return (
    <Grid
      sx={!disabled ? styles.button : {}}
      onClick={() => {
        if (!disabled) onButtonClick();
      }}
    >
      <DynamicIcon
        style={{
          color: !disabled ? color : "rgba(169,169,169,60%)",
        }}
        size={size}
      />
    </Grid>
  );
};

export default IconButton;
