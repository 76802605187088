import { Grid, useMediaQuery } from "@mui/material";

const styles = {
  container: {
    padding: "20px",
    borderRadius: "10px",
  },
};

const InfoPageWrapper = ({ children }) => {
  const largerThanSM = useMediaQuery("(min-width:600px)");

  return (
    <Grid
      container
      item
      sx={{
        ...styles.container,
        boxShadow: !largerThanSM
          ? "none"
          : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      }}
      direction="column"
    >
      {children}
    </Grid>
  );
};

export default InfoPageWrapper;
