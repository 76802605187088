import { Grid, Typography } from "@mui/material";

const styles = {
  container: {
    backgroundColor: "#f8f8f8",
    borderTop: "1px solid #e7e7e7",
    marginTop: "auto",
    padding: "10px",
  },
};

const Footer = () => {
  return (
    <Grid container item sx={styles.container} justifyContent="center" alignItems="center">
      <Grid item>
        <Typography textAlign="center" fontSize="10px">
          Copyright © 2023. All rights reserved by The University of Akron and
          Zips Cubing
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
