import { auth } from "../configurations/firebase-config";
import { useDispatch, useSelector } from "react-redux";
import { setDisplayName, setIsAdmin, setUser } from "../store/userSlice";
import { useEffect } from "react";
import { getTimeData } from "../store/timeDataSlice/actionCreators";
import { getUserInfo } from "../store/userSlice/actionCreators";

const useUpdateUser = () => {
  const dispatch = useDispatch();
  const timeData = useSelector((state) => state.timeData.data);

  // update user information on user change
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        if (user.displayName !== null) {
          dispatch(setDisplayName(user.displayName));
        }
        dispatch(getUserInfo(user.toJSON()));
      } else {
        dispatch(setUser({}));
        dispatch(setDisplayName(""));
        dispatch(setIsAdmin(false));
      }
    });
  }, [dispatch]);

  // get initial time data
  useEffect(() => {
    if (timeData.length === 0) {
      dispatch(getTimeData());
    }
  }, [dispatch, timeData]);
};

export default useUpdateUser;
