import { Grid, useMediaQuery, Snackbar, Alert } from "@mui/material";
import Results from "./components/Results";
import Home from "./components/Home";
import Rules from "./components/Rules";
import SignUp from "./components/SignUp";
import LogIn from "./components/LogIn";
import NavBar from "./components/NavBar";
import Users from "./components/Users";
import Footer from "./components/Footer";
import { Route, Routes as Switch } from "react-router-dom";
import useUpdateUser from "./hooks/useUpdateUser";
import { useSelector, useDispatch } from "react-redux";
import { setOpen, setSeverity } from "./store/alertDataSlice";

const App = () => {
  const { message, severity, open } = useSelector((state) => state.alertData);
  const dispatch = useDispatch();
  const largerThanSM = useMediaQuery("(min-width:600px)");

  useUpdateUser();

  return (
    <Grid container minHeight="100vh" direction="column">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={() => {
          dispatch(setOpen(false));
          dispatch(setSeverity("success"));
        }}
      >
        <Alert
          onClose={() => {
            dispatch(setOpen(false));
            dispatch(setSeverity("success"));
          }}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      <NavBar />
      <Grid
        item
        container
        justifyContent="center"
        marginBottom="20px"
        marginTop={largerThanSM ? "80px" : "60px"}
      >
        <Grid item xs={12} sm={11} md={10}>
          <Switch>
            <Route path="/results" element={<Results />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/users" element={<Users />} />
            <Route path="/" element={<Home />} />
          </Switch>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
};

export default App;
