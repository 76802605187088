import {
  Grid,
  TextField,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import * as Icon from "react-feather";

const styles = {
  selectorButtons: {
    borderRadius: "10px",
    border: "1px solid black",
    height: "min-content",
    width: "min-content",
    backgroundColor: "#e7e7e7",
    "&:hover": {
      cursor: "pointer",
    },
  },
  icons: {
    marginTop: "4px",
  },
};

const StepperTextSelect = ({
  onValuesChange,
  toggleResetValue,
  disabled = false,
  initialValue = null,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [solve1, setSolve1] = useState("");
  const [solve2, setSolve2] = useState("");
  const [solve3, setSolve3] = useState("");
  const [solve4, setSolve4] = useState("");
  const [solve5, setSolve5] = useState("");
  const setSolves = useRef();

  const solves = [solve1, solve2, solve3, solve4, solve5];
  setSolves.current = [setSolve1, setSolve2, setSolve3, setSolve4, setSolve5];

  useEffect(() => {
    onValuesChange(solves);
    // eslint-disable-next-line
  }, solves);

  useEffect(() => {
    setActiveStep(0);
    setSolves.current.forEach((el, index) => {
      if (initialValue) {
        const initialValueArray = initialValue.replace(/[()]/g, "").split(" ");
        el(initialValueArray[index]);
      } else el("");
    });
  }, [initialValue, toggleResetValue, disabled]);

  return (
    <>
      <Stepper activeStep={activeStep} sx={{ padding: "16px 0px 0px 10px" }}>
        {[...Array(5).keys()].map((el) => (
          <Step key={el}>
            <StepLabel />
          </Step>
        ))}
      </Stepper>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid
          item
          sx={styles.selectorButtons}
          onClick={() => {
            if (activeStep > 0) setActiveStep((prev) => --prev);
          }}
        >
          <Tooltip title="Previous Solve" arrow>
            <Icon.ChevronLeft
              size="40px"
              style={{ marginTop: "4px", marginRight: "2px" }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={7} sm={9}>
          <TextField
            value={solves[activeStep]}
            onChange={(e) => {
              setSolves.current[activeStep]((prev) => {
                prev = e.target.value;
                return prev;
              });
            }}
            required={!initialValue}
            fullWidth
            name="indivSolve"
            label={`Solve #${activeStep + 1}`}
            id="indivSolve"
            autoComplete="indivSolve"
            disabled={disabled}
            InputLabelProps={{ shrink: initialValue ? true : undefined }}
          />
        </Grid>
        <Grid
          item
          sx={styles.selectorButtons}
          onClick={() => {
            if (activeStep < 4) setActiveStep((prev) => ++prev);
          }}
        >
          <Tooltip title="Next Solve" arrow>
            <Icon.ChevronRight
              size="40px"
              style={{ marginTop: "4px", marginLeft: "2px" }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default StepperTextSelect;
