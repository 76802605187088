import { Grid, Typography } from "@mui/material";

const styles = {
  container: {
    backgroundColor: "#f8f8f8",
    padding: "10px 10px",
    marginTop: "20px",
    borderRadius: "10px",
  }
};

const ListCard = ({ information, list }) => {
  return (
    <Grid container item direction="column" sx={styles.container}>
      <Grid item marginBottom="10px">
        <Typography fontSize="20px" variant="body2">{information}</Typography>
      </Grid>
      {list.map((item, index) => (
        <Grid item key={index} marginLeft="20px">
          <Typography variant="subtitle2">{index + 1}. {item}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ListCard;