import {
  Modal,
  Paper,
  Grid,
  Typography,
  TextField,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import StepperTextSelect from "./StepperTextSelect";
import * as Icon from "react-feather";
import TableSelector from "./tableSelector";
import data from "./cubeData.json";
import { useEffect, useState } from "react";
import { convertToSeconds } from "../../generalFunctions/convertToSeconds";
import { convertToDate } from "../../generalFunctions/convertToDate";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../store/timeDataSlice/actionCreators";
import { setTimeResponse } from "../../store/timeDataSlice";
import { setMessageAndOpen } from "../../store/alertDataSlice";
import { markSlowAndFastTimes } from "../../generalFunctions/markSlowAndFastTimes";
import dayjs from "dayjs";

const styles = {
  container: {
    height: "max-content",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    maxWidth: "550px",
  },
  backButton: {
    position: "absolute",
    right: "0%",
    top: "0%",
    padding: "5px 5px 1px 5px",
    marginRight: "5px",
    marginTop: "5px",
    borderRadius: "30px",
    marginLeft: "auto",
    height: "max-content",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "pointer",
    },
  },
};

const CreateTimeModal = ({
  handleClose,
  handleSubmitClose,
  isOpen,
  initialEvent,
  initialSolveType,
}) => {
  const { cubeEvents, solveTypes } = data;
  const [event, setEvent] = useState(0);
  const [solveType, setSolveType] = useState(0);
  const [time, setTime] = useState("");
  const [date, setDate] = useState(null);
  const [checked, setChecked] = useState(false);
  const [markOffical, setMarkOffical] = useState(false);
  const [error, setError] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [ao5Solve, setAo5solve] = useState([]);
  const [toggleResetValue, setToggleResetValue] = useState(true);
  const [ao5SolveComplete, setAo5solveComplete] = useState(false);
  const [userForSolve, setUserForSolve] = useState(null);

  const largerThanMD = useMediaQuery("(min-width:900px)");
  const largerThanSM = useMediaQuery("(min-width:600px)");
  const largerThanXS = useMediaQuery("(min-width:350px)");

  const dispatch = useDispatch();
  const { user, isAdmin } = useSelector((state) => state.user);
  const timeResponse = useSelector((state) => state.timeData.response);
  const allUsers = useSelector((state) => state.allUsers.users);

  const handleModalClose = () => {
    setError("");
    setTime("");
    setUserForSolve(user.displayName);
    setDate(null);
    setMarkOffical(false);
    setToggleResetValue((prev) => !prev);
    setChecked(false);
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedTime = convertToSeconds(time);
    const formattedDate = convertToDate(date);

    let tempError = "";
    if (formattedTime === 0) {
      tempError = "*Invalid time format";
      setTime("");
    } else if (formattedDate === "") {
      tempError = "*Invalid date format, year can be (2020 - Current)";
      setDate(null);
    }

    let tempUserForSolve = {};
    if (isAdmin) {
      tempUserForSolve = allUsers.find(
        (el) =>
          el.name.trim().toLowerCase() === userForSolve.trim().toLowerCase()
      );
    }

    let newAo5Solve = [];
    if (solveTypes[solveType] === "ao5") {
      for (let i = 0; i < ao5Solve.length; i++) {
        newAo5Solve[i] = convertToSeconds(ao5Solve[i]);
        if (newAo5Solve[i] === 0) {
          tempError = "*One or more solve times is invalid";
          setToggleResetValue((prev) => !prev);
          break;
        }
      }
    }

    if (tempError.length === 0) {
      let bodyObj =
        solveTypes[solveType] === "ao5"
          ? {
              time: formattedTime,
              date: formattedDate,
              type: solveTypes[solveType] + cubeEvents[event],
              comp: markOffical,
              indivSolves: markSlowAndFastTimes(newAo5Solve),
            }
          : {
              time: formattedTime,
              date: formattedDate,
              type: solveTypes[solveType] + cubeEvents[event],
              comp: markOffical,
            };

      setDisableButton(true);
      dispatch(
        setDocument(
          `${process.env.REACT_APP_DEFAULT_COLLECTION}/${
            isAdmin ? tempUserForSolve.id : user.uid
          }/solves`,
          bodyObj,
          true
        )
      );
    }
    setError(tempError);
  };

  useEffect(() => {
    setUserForSolve(user.displayName);
  }, [user.displayName]);

  useEffect(() => {
    if (ao5Solve.length === 0) return;

    ao5Solve.forEach((el) => {
      if (el.length === 0) {
        setAo5solveComplete(false);
        return;
      }
      setAo5solveComplete(true);
    });
  }, [ao5Solve]);

  useEffect(() => {
    const handleWriteRequestErrors = () => {
      if (timeResponse.length > 0 && isOpen) {
        if (timeResponse === "success") {
          handleSubmitClose(event, solveType);
          setError("");
          setDate(null);
          setTime("");
          setUserForSolve(user.displayName);
          setMarkOffical(false);
          setToggleResetValue((prev) => !prev);
          setChecked(false);
          dispatch(setMessageAndOpen("Time Successfully Added!"));
        } else if (
          timeResponse !== "successUpdated" &&
          timeResponse !== "successDeleted"
        ) {
          setError(timeResponse);
          setDate(null);
          setTime("");
          setUserForSolve(user.displayName);
          setMarkOffical(false);
          setToggleResetValue((prev) => !prev);
          setChecked(false);
        }
        dispatch(setTimeResponse(""));
        setDisableButton(false);
      }
    };

    handleWriteRequestErrors();
    // eslint-disable-next-line
  }, [dispatch, timeResponse]);

  useEffect(() => {
    setEvent(initialEvent);
    setSolveType(initialSolveType);
  }, [initialEvent, initialSolveType, isOpen]);

  return (
    <Modal
      onClose={handleModalClose}
      open={isOpen}
      disableAutoFocus
      closeAfterTransition
    >
      <Grid
        component={Paper}
        container
        sx={{
          ...styles.container,
          width: largerThanMD
            ? "60%"
            : largerThanSM
            ? "75%"
            : largerThanXS
            ? "90%"
            : "100%",
        }}
        direction="column"
        wrap="nowrap"
      >
        <Grid sx={styles.backButton} onClick={handleModalClose}>
          <Icon.X size={30} />
        </Grid>
        <Grid item container justifyContent="center">
          <Typography sx={{ margin: "20px 10px 0px 10px" }} variant="h5">
            Add New {cubeEvents[event]} {solveTypes[solveType]}
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <TableSelector
            array={cubeEvents}
            onSelectionChange={(data) => {
              setEvent(data);
            }}
            passThroughValue={initialEvent}
          />
          <TableSelector
            array={solveTypes}
            onSelectionChange={(data) => {
              setSolveType(data);
            }}
            passThroughValue={initialSolveType}
          />
        </Grid>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            mt: 3,
            width: "80%",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <Grid container spacing={2} direction="column">
            {isAdmin && (
              <Grid item>
                <Autocomplete
                  value={userForSolve}
                  id="user"
                  options={allUsers.map((el) => el.name)}
                  onChange={(event, newValue) => {
                    setUserForSolve(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="User"
                      variant="standard"
                      required
                      fullWidth
                      name="user"
                      autoComplete="user"
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item>
              <TextField
                variant="standard"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
                fullWidth
                id="time"
                label={solveType === 0 ? "Solve Time" : "Average Time"}
                name="time"
                autoComplete="time"
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Date Of Solve"
                    value={date ? dayjs(date) : null}
                    onChange={(e) => {
                      // eslint-disable-next-line
                      if (e.$M !== NaN && e.$D !== NaN && e.$y !== NaN)
                        setDate(`${e.$M + 1}/${e.$D}/${e.$y}`);
                    }}
                    disableFuture
                    slotProps={{
                      textField: {
                        variant: "standard",
                        required: true,
                        fullWidth: true,
                        id: "date",
                      },
                    }}
                    sx={{
                      "& .MuiButtonBase-root": {
                        marginRight: 0,
                      },
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            {solveTypes[solveType] === "ao5" && (
              <StepperTextSelect
                onValuesChange={(values) => {
                  setAo5solve(values);
                }}
                toggleResetValue={toggleResetValue}
              />
            )}
            <Grid style={{ padding: "16px 0px 0px 20px" }} item container>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={() => {
                      setChecked((state) => !state);
                    }}
                  />
                }
                label="My time is truthful*"
              />
              {isAdmin && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={markOffical}
                      onChange={() => {
                        setMarkOffical((prev) => !prev);
                      }}
                    />
                  }
                  label="Mark Official"
                />
              )}
              {error.length > 0 && (
                <Grid item xs={12}>
                  <Typography color="#ff5555" variant="subtitle2">
                    {error}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={
              time.length === 0 ||
              !date ||
              !checked ||
              disableButton ||
              (isAdmin && !userForSolve) ||
              (solveTypes[solveType] === "ao5" && !ao5SolveComplete)
            }
          >
            Add Time
          </Button>
        </Box>
      </Grid>
    </Modal>
  );
};

export default CreateTimeModal;
