const MONTHS = [
  "January",
  "Febuary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const convertToDate = (date) => {
  let splits = date.split("/");
  if (
    splits.length === 3 &&
    !isNaN(splits[0]) &&
    !isNaN(splits[1]) &&
    !isNaN(splits[2]) &&
    +splits[0] >= 0 &&
    +splits[0] <= 11 &&
    +splits[1] >= 1 &&
    +splits[1] <= 31 &&
    +splits[2] >= 2020 &&
    +splits[2] <= 2100
  ) {
    return `${MONTHS[Math.round(+splits[0]) - 1]} ${Math.round(+splits[1])}, ${Math.round(+splits[2])}`;
  } else {
    return "";
  }
};
