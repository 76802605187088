import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  response: "",
  readResponse: "",
  loading: false,
};

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUsersResponse: (state, action) => {
      state.response = action.payload;
    },
    setUsersReadResponse: (state, action) => {
      state.readResponse = action.payload;
    },
    setUsersLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setUsers,
  setUsersResponse,
  setUsersReadResponse,
  setUsersLoading,
} = allUsersSlice.actions;

export default allUsersSlice.reducer;
