import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  isLoading: false,
  response: "",
  readResponse: "",
};

const timeDataSlice = createSlice({
  name: "timeData",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setTimeResponse: (state, action) => {
      state.response = action.payload;
    },
    setTimeReadResponse: (state, action) => {
      state.readResponse = action.payload;
    },
  },
});

export const { setData, setIsLoading, setTimeResponse, setTimeReadResponse } = timeDataSlice.actions;

export default timeDataSlice.reducer;
