import { Grid, Typography } from "@mui/material";
import * as Icon from "react-feather";
import { useNavigate } from "react-router-dom";

const styles = {
  itemContainer: {
    height: "max-content",
    width: "max-content",
    padding: "9px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      cursor: "pointer",
    },
  },
};

const NavBarItem = ({
  name,
  href = "",
  iconName,
  additionalStyles,
  iconStyles,
  onClick = () => {},
}) => {
  const IconTag = Icon[iconName];
  const navigate = useNavigate();

  const handleClick = () => {
    onClick();
    if (href.length > 0) {
      navigate(href);
    }
  };

  return (
    <Grid
      container
      item
      sx={{ ...styles.itemContainer, ...additionalStyles }}
      onClick={handleClick}
      wrap="nowrap"
    >
      <Grid item>
        <IconTag size={23} color="#555555" style={iconStyles} />
      </Grid>
      {name && (
        <Grid item sx={{ padding: "3px 0px 0px 5px" }}>
          <Typography fontSize="14px">{name}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NavBarItem;
