import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Tooltip,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as MuiButton,
} from "@mui/material";
import Button from "../common/Button";
import CreateTimeModal from "./CreateTimeModal";
import TableSelector from "./tableSelector";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import createAndSortTimesByKey from "./createAndSortTimesByKey";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import data from "./cubeData.json";
import { useNavigate } from "react-router-dom";
import useIsOverflow from "../../hooks/useIsOverflow";
import EditTimesModal from "./EditTimesModal";
import { convertDateToNumbers } from "../../generalFunctions/convertDateToNumbers";
import { setMessageAndOpen, setSeverity } from "../../store/alertDataSlice";
import { setResponse } from "../../store/userSlice";

const Results = () => {
  const { cubeEvents, solveTypes } = data;
  const timeData = useSelector((state) => state.timeData.data);
  const timeReadResponse = useSelector((state) => state.timeData.readResponse);
  const user = useSelector((state) => state.user.user);
  const userResponse = useSelector((state) => state.user.response);
  const navigate = useNavigate();
  const ref = useRef();
  const isOverflow = useIsOverflow(ref);
  const largerThanSM = useMediaQuery("(min-width:600px)");

  const [currentTimeData, setCurrentTimeData] = useState([]);
  const [event, setEvent] = useState(0);
  const [displayStyle, setDisplayStyle] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [timeToView, setTimeToView] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const dispatch = useDispatch();

  useDocumentTitle("Zips Cubing - Results");

  useEffect(() => {
    if (timeData.length === 0) return;

    setCurrentTimeData(
      createAndSortTimesByKey(
        timeData,
        solveTypes[displayStyle] + cubeEvents[event]
      )
    );
  }, [timeData, cubeEvents, solveTypes, displayStyle, event]);

  useEffect(() => {
    const handleUserInfoResponse = () => {
      if (userResponse.length > 0) {
        if (userResponse === "ReinstateUser") {
          setDialogOpen(true);
        } else if (userResponse === "ReinstateUser2") {
          dispatch(setSeverity("info"));
          dispatch(setMessageAndOpen("Re-signup to restore your account"));
          navigate("/signup");
        } else {
          dispatch(setSeverity("error"));
          dispatch(setMessageAndOpen(`Error: ${userResponse}`));
          dispatch(setResponse(""));
        }
      }
    };

    handleUserInfoResponse();
  }, [dispatch, navigate, userResponse]);

  const handleSelectorChange = (key, data) => {
    if (key === "event") setEvent(data);
    else setDisplayStyle(data);
  };

  return (
    <>
      {timeReadResponse.length === 0 ? (
        <>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: largerThanSM ? "75vh" : "82vh",
              boxShadow: isOverflow
                ? "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%), inset 0px -11px 12px -10px rgba(0, 0, 0, 0.5)"
                : "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
            }}
            ref={ref}
          >
            <Grid container justifyContent="space-between" wrap="nowrap">
              <TableSelector
                array={cubeEvents}
                onSelectionChange={(data) => {
                  handleSelectorChange("event", data);
                }}
                passThroughValue={event}
              />
              <TableSelector
                array={solveTypes}
                onSelectionChange={(data) => {
                  handleSelectorChange("type", data);
                }}
                passThroughValue={displayStyle}
              />
            </Grid>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 600 }}>#</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Name</TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    {cubeEvents[event]} {solveTypes[displayStyle]}
                  </TableCell>
                  {solveTypes[displayStyle] === "ao5" && (
                    <TableCell sx={{ fontWeight: 600 }} align="right">
                      Solves
                    </TableCell>
                  )}
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: 600 }} align="right">
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentTimeData.map((solve, index) => (
                  <Tooltip
                    key={currentTimeData[index].id}
                    title="View Solve Information"
                    arrow
                  >
                    <TableRow
                      onClick={() => {
                        setEditModalOpen(true);
                        setTimeToView({
                          ...currentTimeData[index],
                          date: convertDateToNumbers(
                            currentTimeData[index].date
                          ),
                        });
                      }}
                      sx={{
                        "&:hover": {
                          cursor: "pointer",
                          backgroundColor: "#e7e7e7",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: solve.comp ? "rgb(255, 17, 17)" : "inherit",
                          fontWeight: solve.comp ? "bold" : "inherit",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell>
                        {solve.name} ({solve.uaid})
                      </TableCell>
                      <TableCell align="right">{solve.time}</TableCell>
                      {solveTypes[displayStyle] === "ao5" && (
                        <TableCell align="right">{solve.indivSolves}</TableCell>
                      )}
                      <TableCell
                        sx={{
                          color: solve.comp ? "rgb(255, 17, 17)" : "inherit",
                          fontWeight: solve.comp ? "bold" : "inherit",
                        }}
                        align="right"
                      >
                        {solve.comp ? "Official" : "Unofficial"}
                      </TableCell>
                      <TableCell align="right">{solve.date}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            iconName="Plus"
            onClick={() => {
              if (user.uaid) setModalOpen(true);
              else if (user.hasOwnProperty("uid")) navigate("/signup");
              else navigate("/login");
            }}
            color="#1e90ff"
            additonalStyles={{
              marginLeft: "auto",
              marginTop: "5px",
              marginRight: "5px",
            }}
          >
            Add Solve
          </Button>
        </>
      ) : (
        <Typography marginTop="30px" align="center" variant="h3">
          OOPS... Something went wrong
        </Typography>
      )}
      <CreateTimeModal
        isOpen={modalOpen}
        handleClose={() => {
          setModalOpen(false);
        }}
        handleSubmitClose={(eve, solveType) => {
          setEvent(eve);
          setDisplayStyle(solveType);
          setModalOpen(false);
        }}
        initialEvent={event}
        initialSolveType={displayStyle}
      />
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <DialogTitle>Reinstate User</DialogTitle>
        <DialogContent>
          <DialogContentText>{`The user by name: ${user.displayName} has been deleted... Re-create User?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => {
              setDialogOpen(false);
              dispatch(setResponse("ReinstateUser2"));
            }}
          >
            Confirm
          </MuiButton>
        </DialogActions>
      </Dialog>
      {timeToView && (
        <EditTimesModal
          isOpen={editModalOpen}
          handleClose={() => {
            setEditModalOpen(false);
          }}
          timeData={timeToView}
        />
      )}
    </>
  );
};

export default Results;
