export const convertToSeconds = (time) => {
  if (time.includes(":")) {
    const splits = time.split(":");
    if (
      splits.length === 2 &&
      !isNaN(splits[0]) &&
      +splits[0] >= 0 &&
      +splits[0] < 60 &&
      !isNaN(splits[1]) &&
      +splits[1] >= 0 &&
      +splits[1] < 60
    ) {
      return Math.floor(+splits[0]) * 60 + (Math.floor(+splits[1] * 100) / 100);
    } else {
      return 0;
    }
  } else {
    if (+time > 0 && +time < 3600) return (Math.floor(+time * 100) / 100);
    else return 0;
  }
};
